$breakpoints: (
  mobile: 767px,
  tablet-min: 768px,
  tablet-max: 992px,
  desktop: 1440px,
);

@mixin mobile-only {
  @media screen and (max-width: map-get($breakpoints, mobile)) {
    @content;
  }
}
@mixin tablet-only {
  @media screen and (max-width: map-get($breakpoints, tablet-max)) and (min-width: map-get($breakpoints, tablet-min)) {
    @content;
  }
}
@mixin desktop-only {
  @media screen and (max-width: map-get($breakpoints, desktop)) {
    @content;
  }
}
