@import '../containers//endpoints.scss';
:root {
  --primary-color: #010712;
  --secondary-color: #818386;
  --bg-color: #fcfdfd;
  --button-color: #3b3636;
  --h1-color: #3f444c;
}
.Title {
  position: relative;
  color: #000;
  font-size: 50px;
  text-align: center;
  margin-top: 2em;
  font-family: 'Source Code Pro', monospace;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
  @include mobile-only {
    font-size: 33px;
    /*margin-top: 5em;*/
  }
}

h2 span {
  transition: 0.5s linear;
}
h2:hover span:nth-child(1) {
  margin-right: 3px;
}
h2:hover span:nth-child(1):after {
  content: '';
}
h2:hover span:nth-child(2) {
  margin-left: 10px;
}
h2:hover span {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}
.topskil {
  margin-top: -53em;
  @include mobile-only {
    margin-top: -53em;
  }
}
.smoke-loader {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  top: 50%;
  @include mobile-only {
    display: none;
  }
  @include tablet-only {
    margin-top: -3em;
  }
}
.coffee-cup {
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.smoke-wave {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0px;
  left: 0px;
}

.smoke1 {
  width: 6px;
  height: 30px;
  float: left;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 3px;
}
.smoke1:before,
.smoke1:after {
  content: '';
  background: #adb5bd;
  position: absolute;
  animation: load 0.9s infinite reverse ease-in-out;
  border-radius: 3px;
}
.smoke2 {
  width: 6px;
  height: 15px;
  float: left;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 3px;
}
.smoke2:before,
.smoke2:after {
  content: '';
  background: #adb5bd;
  position: absolute;
  animation: load 0.6s infinite reverse ease-in-out;
  border-radius: 3px;
  margin-top: 5px;
}
.smoke3 {
  width: 6px;
  height: 30px;
  float: left;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 3px;
}
.smoke3:before,
.smoke3:after {
  content: '';
  background: #adb5bd;
  position: absolute;
  animation: load 0.9s infinite 0.5s reverse ease-in-out;
  border-radius: 3px;
}

@keyframes load {
  0% {
    top: 0;
    height: 30px;
    width: 6px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 29px;
    height: 15px;
    width: 6px;
    opacity: 0;
  }
}
