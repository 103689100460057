@import '../endpoints.scss';
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #282a57;
  color: #fff;
  @include mobile-only {
  }
}
.arrow--up {
  width: 3%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 3em;
  border-radius: 50%;
  opacity: 0.8;
  transition: background 0.5s;
  padding: 0;
  outline: 0;
  cursor: pointer;
  right: 10em;
  bottom: 6em;
  border: none;
  position: absolute;
  @include mobile-only {
    width: 9%;
    height: 2em;
    right: 1em;
    bottom: 2.4em;
  }
  @include tablet-only {
    width: 5%;
    right: 3em;
    bottom: 5em;
  }
}
.icone {
  color: white;
  font-size: 1.5em;
}

i {
  transition: transform 0.4s;
  &:hover {
    box-shadow: 0px 4px 14px rgba(255, 255, 255, 0.5);
    opacity: 0.9;
    transform: scale(1.4);
  }
}
.copyright {
  margin-bottom: 2em;
  color: white;
  margin-left: 5em;
  @include mobile-only {
    margin-left: 1em;
    font-size: 0.4em;
  }
}
.socialinks {
  display: flex;
  color: white;
  align-items: center;
  list-style-type: none;
  margin-top: 1em;
  @include mobile-only {
    font-size: 0.7em;
    margin-right: 8em;
  }
  .link i {
    font-size: 2.5em;
    margin-left: 1em;
    color: white;
  }
}
