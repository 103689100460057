@import '../../containers//endpoints.scss';
.EventCard__img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}
.EventCard__img:hover {
  transform: scale(1.05);
}
.EventCard {
  position: relative;
  width: 25%;
  height: 20em;
  cursor: pointer;

  &--small {
    width: 274px;
    height: 190px;
    .EventCard__imageContainer {
      height: 126px;
      min-height: auto;
      overflow: hidden;
    }

    .EventCard__descriptionContainer {
      padding: 0;
      padding-top: 12px;
      font-size: 12px;
    }
    .EventCard__month {
      margin-top: 0;
      font-size: 8px;
    }
    .EventCard__label {
      font-size: 8px;
      width: 75px;
      height: 21px;
    }
  }
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  overflow: hidden;
  margin: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: transform 0.4s;
  &:hover {
    &:hover {
      color: #fff;
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff;
    }

    transform: scale(1.1);
  }

  &__label {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #d60040;
    font-size: 10px;
    width: 99px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  &__imageContainer {
    min-height: 13em;
    overflow: hidden;
  }
  &__descriptionContainer {
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    padding: 0.7em;
    width: 80%;
    @include mobile-only {
      padding: 0;
    }
  }
  &__title {
    font-size: 16px;
    text-transform: capitalize;
  }
  &__month {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin-top: 8px;
  }
  @include mobile-only {
    width: 80%;
    max-height: 17em;
  }
  @include tablet-only {
    width: 40%;
  }
}
