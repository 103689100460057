html,
body {
  max-width: 1920px;
  margin: auto;
  padding: 0;
  font-family: 'Source Code Pro', monospace;
  font-weight: bold;
}

* {
  box-sizing: border-box;
}
