.col,
.row {
  display: flex;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ListContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  animation: 2s ease-in-out fadeInUp;
}

p {
  margin: 0;
}
.row {
  justify-content: space-around;
}
.col {
  flex-direction: column;
  margin: 16px;
}
.Pagination {
  text-decoration: none;
  display: inline;
  margin: 2px;
}
